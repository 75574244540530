export const enhancer = (el) => {
  el.addEventListener('click', function(e) {
    if (this.classList.contains('site-header__nav-primary-hamburger--expanded')) {
      this.classList.remove('site-header__nav-primary-hamburger--expanded');
      this.classList.add('site-header__nav-primary-hamburger--collapse');
      window.location.href = '#top';
      e.preventDefault();
    } else {
      this.classList.remove('site-header__nav-primary-hamburger--collapse');
      this.classList.add('site-header__nav-primary-hamburger--expanded');
    }
  });
};
